import { initializeApp, getApps, getApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDaSW9mPLrPk3_suBoJi4f7KIyKHFyC1r8",
    authDomain: "thinking-ai.firebaseapp.com",
    projectId: "thinking-ai",
    storageBucket: "thinking-ai.firebasestorage.app",
    messagingSenderId: "991634796591",
    appId: "1:991634796591:web:6326747261d22bf70916da",
    measurementId: "G-FECWHQH67J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { app, db, auth, analytics };