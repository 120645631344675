import React from "react";

function RenameModal({ newChatName, setNewChatName, handleRenameChat, closeModal }) {
    const handleSave = () => {
        handleRenameChat();
        closeModal();
    };

    return (
        <div className="popup-overlay" onClick={closeModal}>
            <div className="popup-content rename-modal" onClick={(e) => e.stopPropagation()}>
                <h3>Rename Chat</h3>
                <input
                    type="text"
                    value={newChatName}
                    onChange={(e) => setNewChatName(e.target.value)}
                    placeholder="Enter new name"
                />
                <div className="popup-actions">
                    <button onClick={closeModal} className="popup-cancel-button">Cancel</button>
                    <button onClick={handleSave} className="popup-action-button">Save</button>
                </div>
            </div>
        </div>
    );
}

export default RenameModal;