import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../configs/firebaseConfig";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import "../../css/Welcome-Pages/Details-Gathering.css";
import thinkingailogo from "../../images/thinkingai-logo.png";
import googleLogo from "../../images/google-logo.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UserForm() {
    const [agreed, setAgreed] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    // Check if the user is already signed in and redirect to the dashboard
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (userData && userData.displayName) {
            toast.success("Login Successfully");
            console.log("User already signed in. Redirecting to dashboard...");
            navigate("/welcome");
        }
    }, [navigate]);

    const handleGoogleSignIn = async () => {
        if (!agreed) {
            setErrorMessage("Please accept the Privacy Policy to continue.");
            setIsErrorPopupOpen(true);
            return;
        }

        console.log("Privacy Policy accepted. Starting Google Sign-In...");
        setIsLoading(true);

        const provider = new GoogleAuthProvider();

        try {

            signInWithPopup(auth, provider).then(async (result) => {
                console.log(result);
                if (result.user) {
                    let displayName = result.user.displayName;
                    localStorage.setItem("userData", JSON.stringify({ displayName }));
                    navigate("/welcome");
                }

            });
            // Sign in with Google Popup
            // const result = await signInWithPopup(auth, provider);
            // console.log("Google Sign-In Result:", result);

            // const user = result.user;
            // console.log("User data fetched from Google:", user);

            // // Check if the user already exists in Firestore
            // const userDocRef = doc(db, "users", user.email);
            // const userDoc = await getDoc(userDocRef);

            // if (!userDoc.exists()) {
            //     // Save new user to Firestore
            //     await setDoc(userDocRef, {
            //         name: user.displayName,
            //         email: user.email,
            //         agreed: true,
            //     });
            //     console.log("New user saved to Firestore.");
            // }

            // // Save user data locally
            // const userData = { name: user.displayName, email: user.email, agreed: true };
            // localStorage.setItem("userData", JSON.stringify(userData));
            // console.log("User data saved to localStorage:", userData);

            // // Redirect to dashboard
            // console.log("Redirecting to dashboard...");
            // navigate("/dashboard");
        } catch (error) {
            console.error("Google Sign-In Error:", error);
            setErrorMessage("Failed to sign in with Google. Please try again.");
            setIsErrorPopupOpen(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="full-page-form">
            <img className="thinkingaiLogo" src={thinkingailogo} alt="ThinkingAI Logo" />
            <h1 className="form-title">Welcome to ThinkingAI!</h1>
            <p className="privacy-prompt">Please accept the Privacy Policy to continue.</p>

            <form className="user-form">
                <label className="checkbox-label">
                    <input
                        type="checkbox"
                        checked={agreed}
                        onChange={(e) => setAgreed(e.target.checked)}
                        className="form-checkbox"
                    />
                    {" I agree to the "}
                    <span className="privacy-link" onClick={() => setIsModalOpen(true)}>
                        Privacy Policy
                    </span>
                </label>
            </form>

            <button
                onClick={handleGoogleSignIn}
                className={`google-signin-button ${!agreed ? "disabled" : ""}`}
                disabled={!agreed || isLoading}
            >
                {isLoading ? (
                    <span>Signing In...</span>
                ) : (
                    <>
                        <img src={googleLogo} alt="Google Logo" className="google-logo" />
                        Sign In with Google
                    </>
                )}
            </button>

            {/* Privacy Policy Modal */}
            {isModalOpen && (
                <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-button" onClick={() => setIsModalOpen(false)}>
                            &times;
                        </button>
                        <h2>Privacy Policy for ThinkingAI</h2>
                        <p>Last Updated: 11/11/2024</p>
                        <p>ThinkingAI (referred to as "we," "us," or "our") respects your privacy...</p>
                        {/* Add the full Privacy Policy content here */}
                    </div>
                </div>
            )}

            {/* Error Popup */}
            {isErrorPopupOpen && (
                <div className="error-popup-overlay" onClick={() => setIsErrorPopupOpen(false)}>
                    <div className="error-popup" onClick={(e) => e.stopPropagation()}>
                        <button className="error-close-button" onClick={() => setIsErrorPopupOpen(false)}>
                            &times;
                        </button>
                        <p>{errorMessage}</p>
                        <button onClick={() => setIsErrorPopupOpen(false)} className="error-ok-button">
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserForm;
