import React, { useState } from "react";

function ChatInput({ onSendMessage }) {
    const [input, setInput] = useState("");

    const handleSendMessage = () => {
        if (input.trim()) {
            onSendMessage(input);
            setInput("");
        }
    };

    return (
        <div className="chat-input">
            <input
                type="text"
                placeholder="Message ThinkingAI"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
            />
            <button onClick={handleSendMessage}>&#8593;</button>
        </div>
    );
}

export default ChatInput;