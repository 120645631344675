import React from "react";

function Popup({ title, message, qrCodeDataUrl, onClose, onConfirm, actionText }) {
    return (
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>&times;</button>
                <h3>{title}</h3>
                <p>{message}</p>
                {qrCodeDataUrl && <img src={qrCodeDataUrl} alt="QR Code" />}
                <div className="popup-actions">
                    <button onClick={onClose} className="popup-cancel-button">
                        Close
                    </button>
                    {onConfirm && (
                        <button onClick={onConfirm} className="popup-action-button">
                            {actionText}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Popup;