import React, { useState, useEffect, useRef } from "react";
import "../../css/Dashboard.css";
import thinkingailogo from "../../images/thinkingai-logo.png";
import Popup from "./Popup";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuth } from "firebase/auth"; // Import Firebase Auth

function Sidebar({
    isOpen,
    closeSidebar,
    handleNewChat,
    chatHistory,
    handleDeleteChat,
    startRenameChat,
    handleSelectChat,
    selectedChatId,
}) {
    const sidebarRef = useRef(null);
    const [optionsChatId, setOptionsChatId] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // State to track loading for Razorpay

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                closeSidebar();
                setOptionsChatId(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [closeSidebar]);

    const handleSubscription = async () => {
        setIsLoading(true); // Start loading spinner
        const loadScript = (src) => {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = src;
                script.onload = () => resolve(true);
                script.onerror = () => resolve(false);
                document.body.appendChild(script);
            });
        };

        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

        if (!res) {
            toast.error("Failed to load Razorpay SDK. Check your internet connection.");
            setIsLoading(false); // Stop loading spinner
            return;
        }

        try {
            // Fetch user details from Firebase
            const auth = getAuth();
            const user = auth.currentUser;

            if (!user) {
                toast.error("User not logged in. Please log in first.");
                setIsLoading(false); // Stop loading spinner
                return;
            }

            const name = user.displayName || "Your Name"; // Get user's name
            const email = user.email || "your.email@example.com"; // Get user's email

            // Create subscription on the backend
            const subscriptionResponse = await axios.post(
                "https://thinkingai-backend-server.com/create-subscription",
                {
                    plan_id: "plan_PNcPIw9lfrczy2",
                    customer_notify: 1,
                    total_count: 120,
                }
            );

            const subscription = subscriptionResponse.data;

            const options = {
                key: "rzp_live_KALYap1siGOvfH",
                subscription_id: subscription.id,
                name: "ThinkingAI",
                description: "Pro Plan Subscription",
                handler: function (response) {
                    toast.success("Subscription successful!");
                    console.log("Payment successful:", response);
                },
                prefill: {
                    name: name, // Use Firebase user's name
                    email: email, // Use Firebase user's email
                    contact: "9999999999", // Replace with user's contact if available
                },
                theme: {
                    color: "#3399cc",
                },
                modal: {
                    ondismiss: () => {
                        setIsLoading(false); // Stop loading spinner when Razorpay modal closes
                    },
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (err) {
            console.error("Error creating subscription:", err.response || err.message);
            toast.error("Failed to create subscription. Please try again.");
        } finally {
            setIsLoading(false); // Stop loading spinner
        }
    };

    return (
        <>
            <div className={`sidebar ${isOpen ? "open" : ""}`} ref={sidebarRef}>
                <div className="sidebar-header">
                    <img src={thinkingailogo} alt="ThinkingAI Logo" className="sidebar-logo" />
                    <h2>ThinkingAI</h2>
                </div>
                <button className="new-chat-button" onClick={handleNewChat}>+ New Chat</button>
                <nav className="sidebar-nav">
                    <ul>
                        <li onClick={() => setShowPopup(true)}>⚙️ Settings</li>
                        <li onClick={() => setShowPopup(true)}>🧑‍💻 Help & Support</li>
                        <li
                            onClick={handleSubscription}
                            style={{ position: "relative", display: "flex", alignItems: "center" }}
                        >
                            🧑🏻‍🚀 Upgrade Pro
                            {isLoading && (
                                <span
                                    className="loading-icon"
                                    style={{
                                        marginLeft: "8px",
                                        height: "1rem",
                                        width: "1rem",
                                        border: "2px solid #ddd",
                                        borderTop: "2px solid #3399cc",
                                        borderRadius: "50%",
                                        animation: "spin 1s linear infinite",
                                    }}
                                ></span>
                            )}
                        </li>
                        <br />
                        <li><b><u>Chat History</u></b></li>
                    </ul>
                    <ul>
                        {chatHistory.map((chat) => (
                            <li
                                key={chat.id}
                                onClick={() => handleSelectChat(chat.id)}
                                className={selectedChatId === chat.id ? "active" : ""}
                            >
                                {chat.name}
                                <span
                                    className="options-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setOptionsChatId(chat.id === optionsChatId ? null : chat.id);
                                    }}
                                >
                                    ⋮
                                </span>
                                {optionsChatId === chat.id && (
                                    <div className="chat-options">
                                        <button onClick={() => startRenameChat(chat.id)}>Rename</button>
                                        <button onClick={() => handleDeleteChat(chat.id)}>Delete</button>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            {showPopup && (
                <Popup
                    title="Under Development"
                    message="This feature is under development. We’ll let you know once it’s ready!"
                    onClose={() => setShowPopup(false)}
                    actionText="OK"
                />
            )}
            <ToastContainer />
        </>
    );
}

export default Sidebar;
