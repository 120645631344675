import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./Sidebar";
import ProfileMenu from "./ProfileMenu";
import ChatArea from "./ChatArea";
import ChatInput from "./ChatInput";
import RenameModal from "./RenameModal";
import Popup from "./Popup";

function WelcomePage() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [messages, setMessages] = useState([
    { id: 1, text: "Welcome to ThinkingAI!", type: "bot" },
  ]);
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [renameChatId, setRenameChatId] = useState(null);
  const [newChatName, setNewChatName] = useState("");
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState(""); // State to hold the QR code data URL
  const [isListening, setIsListening] = useState(false); // For speech recognition toggle
  const [suggestions, setSuggestions] = useState([]); // Store dynamic suggestions
  const navigate = useNavigate();

  // Function to make the bot's response audible
  const speakText = (text) => {
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = "en-US"; // Set the language
      utterance.rate = 1; // Set the speaking rate
      utterance.pitch = 1; // Set the pitch
      window.speechSynthesis.speak(utterance);
    } else {
      toast.error("Text-to-Speech is not supported in this browser.");
    }
  };

  const startListening = async () => {
    try {
      // Request microphone access
      await navigator.mediaDevices.getUserMedia({ audio: true });

      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition; // Ensure compatibility
      if (!SpeechRecognition) {
        toast.error("Speech Recognition API is not supported in your browser.");
        return;
      }

      const recognition = new SpeechRecognition();
      recognition.lang = "en-US";
      recognition.interimResults = false; // Only process finalized results

      recognition.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join("");
        processTranscript(transcript);

        // Stop recognition after processing
        recognition.stop();
        setIsListening(false);
      };

      recognition.onerror = (error) => {
        console.error("Speech recognition error:", error);
        toast.error("An error occurred while accessing the microphone.");
        setIsListening(false);
      };

      recognition.onend = () => {
        console.log("Speech recognition stopped.");
        setIsListening(false);
      };

      recognition.start();
      setIsListening(true);
    } catch (error) {
      console.error("Microphone access denied:", error);
      toast.error(
        "Microphone access was denied. Please allow it in your browser settings."
      );
    }
  };

  const processTranscript = async (transcript) => {
    console.log("Recognized Speech:", transcript);
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: Date.now(), text: transcript, type: "user" },
    ]);

    setLoading(true);

    try {
      // Mock response instead of actual API call
      const botMessageText = "This is a placeholder response.";
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: Date.now(), text: botMessageText, type: "bot" },
      ]);
      speakText(botMessageText);
    } catch (error) {
      console.error("Error processing request:", error);
      alert(`Error: ${error.message}`);
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: Date.now(), text: "Sorry, something went wrong.", type: "bot" },
      ]);
      speakText("Sorry, something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const stopListening = () => {
    window.speechSynthesis.cancel(); // Stop ongoing speech synthesis
    setIsListening(false);
  };

  const generateSuggestion = (suggestion) => {
    setSuggestions((prev) => [...prev, suggestion]);
    speakText(suggestion); // Optionally speak the suggestion
  };

  // Load chat history from localStorage on component mount
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!userData || !userData.displayName) {
      navigate("/name-email");
    }

    const storedChatHistory = JSON.parse(localStorage.getItem("chatHistory"));
    if (storedChatHistory) {
      setChatHistory(storedChatHistory);
      if (storedChatHistory.length > 0) {
        // Load the first chat by default
        setSelectedChatId(storedChatHistory[0].id);
        setMessages(storedChatHistory[0].messages || []);
      }
    }
  }, [navigate]);

  // Save chat history to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("chatHistory", JSON.stringify(chatHistory));
  }, [chatHistory]);

  const handleSendMessage = async (userInput) => {
    if (!userInput.trim()) return;

    setMessages((prevMessages) => [
      ...prevMessages,
      { id: Date.now(), text: userInput, type: "user" },
    ]);
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api-inference.huggingface.co/models/facebook/blenderbot-400M-distill",
        JSON.stringify({ inputs: userInput }),
        {
          headers: {
            Authorization: `Bearer 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`, // Replace with your Hugging Face API Key
            "Content-Type": "application/json",
          },
        }
      );

      const botMessageText =
        response?.data?.generated_text ||
        (Array.isArray(response.data) && response.data[0]?.generated_text) ||
        "I'm not sure how to respond to that.";

      const newBotMessage = {
        id: Date.now(),
        text: botMessageText,
        type: "bot",
      };

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, newBotMessage];
        updateChatMessagesInHistory(updatedMessages);
        return updatedMessages;
      });

      speakText(botMessageText);
    } catch (error) {
      console.error("Error fetching response from Hugging Face API:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: Date.now(),
          text: "Sorry, I couldn't process that.",
          type: "bot",
        },
      ]);
      speakText("Sorry, I couldn't process that.");
    } finally {
      setLoading(false);
    }
  };

  const updateChatMessagesInHistory = (newMessages) => {
    setChatHistory((prevChatHistory) =>
      prevChatHistory.map((chat) => {
        if (chat.id === selectedChatId) {
          return { ...chat, messages: newMessages };
        }
        return chat;
      })
    );
  };

  return (
    <div className="welcome-page">
      <ToastContainer position="top-right" autoClose={3000} />
      {!isSidebarOpen && (
        <button
          className="sidebar-toggle"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          ☰
        </button>
      )}
      <Sidebar
        isOpen={isSidebarOpen}
        closeSidebar={() => setIsSidebarOpen(false)}
        handleNewChat={() => {
          const newChat = { id: Date.now(), name: "New Chat", messages: [] };
          setChatHistory((prev) => [...prev, newChat]);
          setSelectedChatId(newChat.id);
          setMessages([
            { id: 1, text: "New conversation started.", type: "bot" },
          ]);
        }}
        chatHistory={chatHistory}
        setMessages={setMessages}
        handleDeleteChat={(chatId) => {
          setChatHistory((prev) => prev.filter((chat) => chat.id !== chatId));
          setSelectedChatId(null);
          setMessages([{ id: 1, text: "Welcome to ThinkingAI!", type: "bot" }]);
        }}
        startRenameChat={(chatId) => {
          setRenameChatId(chatId);
          const chat = chatHistory.find((ch) => ch.id === chatId);
          setNewChatName(chat?.name || "");
        }}
        handleSelectChat={(chatId) => {
          const selectedChat = chatHistory.find((chat) => chat.id === chatId);
          setSelectedChatId(chatId);
          setMessages(selectedChat?.messages || []);
        }}
        selectedChatId={selectedChatId}
      />
      <ProfileMenu setQrCodeDataUrl={setQrCodeDataUrl} />
      <div className="main-chat">
        <ChatArea
          messages={messages}
          loading={loading}
          suggestions={suggestions}
        />
        <div className="speech-controls">
          <button
            onClick={isListening ? stopListening : startListening}
            className={`speech-button ${isListening ? "listening" : ""}`}
          >
            {isListening ? "🛑 Stop Listening" : "🎙️ Start Listening"}
          </button>
        </div>
        <ChatInput onSendMessage={handleSendMessage} />
      </div>
      {renameChatId && (
        <RenameModal
          newChatName={newChatName}
          setNewChatName={setNewChatName}
          handleRenameChat={() => {
            setChatHistory((prev) =>
              prev.map((chat) =>
                chat.id === renameChatId ? { ...chat, name: newChatName } : chat
              )
            );
            setRenameChatId(null);
          }}
          closeModal={() => setRenameChatId(null)}
        />
      )}
      {qrCodeDataUrl && (
        <Popup
          title="Connect with Other Devices"
          message="Scan this code to connect with other devices!"
          qrCodeDataUrl={qrCodeDataUrl}
          onClose={() => setQrCodeDataUrl("")}
        />
      )}
    </div>
  );
}

export default WelcomePage;
