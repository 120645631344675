import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode";
import "../../css/Dashboard.css";
import { auth } from "../../configs/firebaseConfig";
import Popup from "./Popup";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuth } from "firebase/auth";

function ProfileMenu({ setQrCodeDataUrl }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State to track loading status
  const [initial, setInitial] = useState("");
  const [showPopup, setShowPopup] = useState(null);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData.displayName) {
      setInitial(userData.displayName.charAt(0).toUpperCase());
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLogout = async () => {
    try {
      localStorage.clear();
      await auth.signOut();
      setShowPopup(null);
      setTimeout(() => {
        navigate("/name-email");
      }, 500);
    } catch (error) {
      console.error("Error logging out:", error);
      setShowPopup(null);
    }
  };

  const generateQRCode = async () => {
    const randomData = `0G96T8142N5965622`;
    const qrCodeDataUrl = await QRCode.toDataURL(randomData);
    setQrCodeDataUrl(qrCodeDataUrl);
    setShowPopup("connect");
  };

  const handleSubscription = async () => {
    setIsLoading(true); // Start loading spinner
    const loadScript = (src) => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => resolve(true);
        script.onerror = () => resolve(false);
        document.body.appendChild(script);
      });
    };

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      toast.error(
        "Failed to load Razorpay SDK. Check your internet connection."
      );
      setIsLoading(false); // Stop loading spinner
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        toast.error("User not logged in. Please log in first.");
        setIsLoading(false); // Stop loading spinner
        return;
      }

      const name = user.displayName || "Your Name";
      const email = user.email || "your.email@example.com";

      const subscriptionResponse = await axios.post(
        "https://thinkingai-backend-server.com/create-subscription",
        {
          plan_id: "plan_PNcPIw9lfrczy2",
          customer_notify: 1,
          total_count: 120,
        }
      );

      const subscription = subscriptionResponse.data;

      const options = {
        key: "rzp_live_KALYap1siGOvfH",
        subscription_id: subscription.id,
        name: "ThinkingAI",
        description: "Pro Plan Subscription",
        handler: function (response) {
          toast.success("Subscription successful!");
          console.log("Payment successful:", response);
        },
        prefill: {
          name: name,
          email: email,
          contact: "9999999999",
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: () => {
            setIsLoading(false); // Stop loading spinner when modal closes
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (err) {
      console.error(
        "Error creating subscription:",
        err.response || err.message
      );
      toast.error("Failed to create subscription. Please try again.");
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  };

  return (
    <div className="profile-menu-container">
      <button
        className="profile-icon"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {initial}
      </button>
      {isMenuOpen && (
        <div ref={menuRef} className="profile-menu-dropdown">
          <p onClick={() => setShowPopup("logout")}>
            <span className="menu-icon">🔓</span> Logout
          </p>
          <p onClick={generateQRCode}>
            <span className="menu-icon">🔗</span> Connect with Other Devices
          </p>
          <p onClick={handleSubscription} style={{ position: "relative" }}>
            <span className="menu-icon">🧑🏻‍🚀</span> Upgrade Pro
            {isLoading && (
              <span
                className="loading-icon"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  height: "1rem",
                  width: "1rem",
                  border: "2px solid #ddd",
                  borderRadius: "50%",
                  borderTop: "2px solid #3399cc",
                  animation: "spin 1s linear infinite",
                }}
              ></span>
            )}
          </p>
        </div>
      )}
      {showPopup === "logout" && (
        <Popup
          title="Confirm Logout"
          message="Are you sure you want to log out?"
          onClose={() => setShowPopup(null)}
          onConfirm={handleLogout}
          actionText="Yes, Logout"
        />
      )}
    </div>
  );
}

export default ProfileMenu;
