import React from "react";

function ChatArea({ messages, loading, suggestions }) {
  return (
    <div className="chat-area">
      {/* Display chat messages */}
      {messages.map((message) => (
        <div
          key={message.id}
          className={`chat-message ${message.type} ${
            message.type === "bot" ? "left" : "right"
          }`}
        >
          {message.text}
        </div>
      ))}

      {/* Loading indicator */}
      {loading && (
        <div className="chat-message bot left typing-indicator">
          <span>Thinking...</span>
        </div>
      )}

      {/* Suggestions section */}
      {suggestions && suggestions.length > 0 && (
        <div className="suggestions">
          <h4>Suggestions</h4>
          {suggestions.map((suggestion, index) => (
            <div key={index} className="suggestion">
              {suggestion}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ChatArea;
