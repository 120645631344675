import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../css/Welcome-Pages/ThinkingAI-Features.css";

// Importing logo and images
import logo from "../../images/thinkingai-logo.png";
import addiction from "../../images/Transition-Images/Addiction.webp";
import Continuepassion from "../../images/Transition-Images/Continue-Passion.webp";
import Familydynamics from "../../images/Transition-Images/Family-Dynamics.webp";
import Financialdevelopment from "../../images/Transition-Images/Financial-Development.webp";
import Lackofrecognition from "../../images/Transition-Images/Lack-Of-Recognition.webp";
import Officepolitics from "../../images/Transition-Images/Office-Politics.webp";
import Peacefulmind from "../../images/Transition-Images/Peaceful-Mind.webp";
import Relationshipmaintandence from "../../images/Transition-Images/Relationship-Maintandence.webp";
import Selfgrowth from "../../images/Transition-Images/Self-Growth.webp";
import Skillgap from "../../images/Transition-Images/Skill-Gap.webp";
import Timemanagement from "../../images/Transition-Images/Time-Management.webp";
import Worklifebalance from "../../images/Transition-Images/Work-Life-Balance.webp";

function ThinkingaiFeatures() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("userData"));
        if (userData && userData.displayName) {
            navigate("/welcome");
        }
        setTimeout(() => setLoading(false), 2000); // Simulating loading time
    }, [navigate]);

    return (
        <div className="root">
            {/* Logo Section */}
            <header className="logo-header">
                {loading ? <div className="logo-skeleton"></div> : <img src={logo} alt="ThinkingAI Logo" className="logo-image" />}
            </header>

            {/* Desktop view */}
            <div className={`features features-desktop ${loading ? "loading" : ""}`}>
                <div className="main-card">
                    <FeatureCard
                        images={[addiction, Continuepassion, Familydynamics]}
                        texts={["Support Healthier Choices", "Foster Personal Passions", "Enhance Family Harmony"]}
                        loading={loading}
                    />
                </div>
                <div className="main-card center-card">
                    {loading ? <div className="text-skeleton"></div> : <h2>Thank You First, Now ThinkingAI Can Help You In...</h2>}
                    {loading ? <div className="button-skeleton"></div> : <Link to="/devices-integration" className="Next-Button">Next</Link>}
                </div>
                <div className="main-card">
                    <FeatureCard
                        images={[Financialdevelopment, Lackofrecognition, Officepolitics]}
                        texts={["Ensure Financial Stability", "Optimize Time Management", "Navigate Workplace Dynamics"]}
                        loading={loading}
                    />
                </div>
                <div className="main-card">
                    <FeatureCard
                        images={[Peacefulmind, Relationshipmaintandence, Selfgrowth]}
                        texts={["Promote Inner Peace", "Strengthen Meaningful Relationships", "Advance Career Growth"]}
                        loading={loading}
                    />
                </div>
                <div className="main-card">
                    <FeatureCard
                        images={[Skillgap, Timemanagement, Worklifebalance]}
                        texts={["Close Skill Gaps", "Optimize Time Management", "Achieve Work-Life Balance"]}
                        loading={loading}
                    />
                </div>
            </div>

            {/* Mobile view */}
            <div className={`features features-mobile ${loading ? "loading" : ""}`}>
                <div className="main-card">
                    <FeatureCard
                        images={[
                            addiction, Continuepassion, Familydynamics, Financialdevelopment, Lackofrecognition,
                            Officepolitics, Peacefulmind, Relationshipmaintandence, Selfgrowth, Skillgap, Timemanagement,
                            Worklifebalance
                        ]}
                        texts={[
                            "Support Healthier Choices", "Foster Personal Passions", "Enhance Family Harmony",
                            "Ensure Financial Stability", "Optimize Time Management", "Navigate Workplace Dynamics",
                            "Promote Inner Peace", "Strengthen Meaningful Relationships", "Advance Career Growth",
                            "Close Skill Gaps", "Optimize Time Management", "Achieve Work-Life Balance"
                        ]}
                        loading={loading}
                    />
                </div>
                <div className="main-card center-card">
                    {loading ? <div className="text-skeleton"></div> : <h3>Now I Can Help You In...</h3>}
                    {loading ? <div className="button-skeleton"></div> : <Link to="/devices-integration" className="Next-Button">Next</Link>}
                </div>
            </div>
        </div>
    );
}

function FeatureCard({ images, texts, loading }) {
    const [index, setIndex] = React.useState(0);
    const [fadeClass, setFadeClass] = React.useState("fade-in");

    React.useEffect(() => {
        const initialDelay = Math.random() * 3000;
        const timerId = setTimeout(() => {
            const intervalId = setInterval(() => {
                setFadeClass("fade-out");
                setTimeout(() => {
                    setIndex((i) => (i + 1) % images.length);
                    setFadeClass("fade-in");
                }, 500);
            }, 4000);

            return () => clearInterval(intervalId);
        }, initialDelay);

        return () => clearTimeout(timerId);
    }, [images.length]);

    return (
        <div className="feature-card">
            {loading ? (
                <div className="image-skeleton"></div>
            ) : (
                <img className={`Feature-images ${fadeClass}`} src={images[index]} alt="Feature" />
            )}
            <p className="Feature-Text">{loading ? <div className="text-skeleton"></div> : texts[index]}</p>
        </div>
    );
}

export default ThinkingaiFeatures;
